import React from 'react';
import './InfoModal.scss';
import SuccessModal from './Success/Success';
import PersonalChecksFeatures from './Features/Features';
import PersonalChecksModalHeader from './Header/Header';

interface InfoModalProps {
  isSuccess: boolean;
  handleSubscribeClick: () => void;
  handleCloseClick: () => void;
  bannerTitle?: React.ReactNode;
  monitorRecordsFlag?: boolean;
}

const PersonalChecksModalBody = ({
  children,
}: {
  children: React.ReactNode;
}) => <div id='personal-checks-modal-body'>{children}</div>;

export default function InfoModal({
  handleCloseClick,
  isSuccess,
  handleSubscribeClick,
  bannerTitle,
  monitorRecordsFlag,
}: InfoModalProps) {
  return (
    <div id='personal-checks-modal'>
      <PersonalChecksModalHeader handleCloseClick={handleCloseClick} />
      <PersonalChecksModalBody>
        {isSuccess ? (
          <SuccessModal />
        ) : (
          <PersonalChecksFeatures
            handleSubscribeClick={handleSubscribeClick}
            bannerTitle={bannerTitle}
            monitorRecordsFlag={monitorRecordsFlag}
          />
        )}
      </PersonalChecksModalBody>
    </div>
  );
}
