import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import { uuid4 } from '@sentry/utils';
import './Features.scss';

type FeatureProp = {
  title: string;
  description: string;
  isOpen?: boolean;
  eventKey: string;
};

type FeaturesProps = {
  icon: string;
  title: string;
  features: FeatureProp[];
};

type PersonalChecksFeaturesProps = {
  handleSubscribeClick?: () => void;
  bannerTitle?: React.ReactNode;
  monitorRecordsFlag?: boolean;
};

const ExpandableFeature = ({
  title,
  description,
  isOpen = false,
  eventKey,
}: FeatureProp) => {
  return (
    <div className='personal-checks-modal-feature'>
      <div className='personal-checks-modal-feature-description'>
        <T value={description} dangerousHTML />
      </div>
    </div>
  );
};

const PersonalChecksModalBodyHeader = ({
  children,
  bannerTitle,
}: {
  children: React.ReactNode;
  bannerTitle?: React.ReactNode;
}) => {
  return (
    <section id='personal-checks-modal-body-header'>
      <h3 id='personal-checks-modal-body-header-title'>{bannerTitle}</h3>
      <div id='personal-checks-modal-body-header-description'>
        <p id='personal-checks-modal-body-header-description-price'>
          <T
            value='components.PersonalChecks.modal.headerPrice'
            dangerousHTML
          />
        </p>
        {children}
      </div>
    </section>
  );
};

function Features({ icon, title, features }: FeaturesProps) {
  return (
    <div id='personal-checks-modal-features-container'>
      <section id='personal-checks-modal-features-header'>
        <div id='personal-checks-modal-features-header-icon'>
          <M.Icon icon={icon} size='20' />
        </div>
        <p id='personal-checks-modal-features-header-title'>
          <T value={title} />
        </p>
      </section>
      <section id='personal-checks-modal-features-body'>
        {features.map(feature => (
          <ExpandableFeature
            key={uuid4()}
            title={feature.title}
            description={feature.description}
            isOpen={feature.isOpen || false}
            eventKey={feature.eventKey}
          />
        ))}
      </section>
    </div>
  );
}

const PersonalChecksModalBodyFeatures = ({
  monitorRecordsFlag,
}: {
  monitorRecordsFlag?: boolean;
}) => (
  <section id='personal-checks-modal-body-features'>
    <h3 id='personal-checks-modal-body-features-title'>
      <T value='components.PersonalChecks.modal.featuresTitle' />
    </h3>
    <div id='personal-checks-modal-body-features-container'>
      <Features
        icon='User'
        title='components.PersonalChecks.modal.features.personalProfile'
        features={[
          {
            title: 'components.PersonalChecks.modal.features.personalProfile',
            description:
              'components.PersonalChecks.modal.features.personalProfileDescription',
            isOpen: true,
            eventKey: 'PERSONAL_CHECKS_PERSONAL_PROFILE_CLICKED',
          },
        ]}
      />
      {!monitorRecordsFlag && (
        <Features
          icon='Lightning'
          title='components.PersonalChecks.modal.features.preapproval'
          features={[
            {
              title: 'components.PersonalChecks.modal.features.preapproval',
              description:
                'components.PersonalChecks.modal.features.preapprovalDescription',
              isOpen: true,
              eventKey: 'PERSONAL_CHECKS_PRE_APPROVAL_CLICKED',
            },
          ]}
        />
      )}
      {monitorRecordsFlag && (
        <Features
          icon='ManageProtection'
          title='components.PersonalChecks.modal.features.recordsMonitoring'
          features={[
            {
              title:
                'components.PersonalChecks.modal.features.recordsMonitoring',
              description:
                'components.PersonalChecks.modal.features.recordsMonitoringDescription',
              isOpen: true,
              eventKey: 'PERSONAL_CHECKS_PRE_APPROVAL_CLICKED',
            },
          ]}
        />
      )}
      {/* <Features
        icon='Security'
        title='components.PersonalChecks.modal.features.protectYourIdentity'
        features={[
          {
            title: 'components.PersonalChecks.modal.features.ssnMonitoring',
            description:
              'components.PersonalChecks.modal.features.ssnMonitoringDescription',
            eventKey: 'PERSONAL_CHECKS_SSN_MONITORING_CLICKED',
          },
          {
            title:
              'components.PersonalChecks.modal.features.globalIdentityMonitoring',
            description:
              'components.PersonalChecks.modal.features.globalIdentityMonitoringDescription',
            eventKey: 'PERSONAL_CHECKS_GLOBAL_IDENTITY_MONITORING_CLICKED',
          },
        ]}
      />
      <Features
        icon='Search'
        title='components.PersonalChecks.modal.features.monitorYourRecords'
        features={[
          {
            title: 'components.PersonalChecks.modal.features.recordsMonitoring',
            description:
              'components.PersonalChecks.modal.features.recordsMonitoringDescription',
            eventKey: 'PERSONAL_CHECKS_RECORDS_MONITORING_CLICKED',
          },
          {
            title: 'components.PersonalChecks.modal.features.expungement',
            description:
              'components.PersonalChecks.modal.features.expungementDescription',
            eventKey: 'PERSONAL_CHECKS_EXPUNGEMENT_CLICKED',
          },
        ]}
      /> */}
    </div>
  </section>
);

const PersonalChecksFeatures = ({
  handleSubscribeClick,
  bannerTitle,
  monitorRecordsFlag,
}: PersonalChecksFeaturesProps) => (
  <>
    <PersonalChecksModalBodyHeader bannerTitle={bannerTitle}>
      <M.Button
        type='button'
        kind='achievement'
        onClick={handleSubscribeClick}
        id='personal-checks-modal-footer-subscribe-button'
      >
        <T value='components.PersonalChecks.modal.subscribe' />
      </M.Button>
    </PersonalChecksModalBodyHeader>
    <PersonalChecksModalBodyFeatures monitorRecordsFlag={monitorRecordsFlag} />
  </>
);

export default PersonalChecksFeatures;
